import { v4 as uuid } from 'uuid';


const tempMenuData: any[] = [
  ['dashboard', 'Dashboard', ['/dashboard']],
  ['home', 'Account Management', [
    ['account_circle', 'App Users', ['/account']],
    ['face', 'Staff Management', ['/staff']],
    ['question_answer', 'Stream Management', ['/streams']],
    ['business', 'Zone Management', ['/zones']],
  ]],
  ['category', 'Asset Management', [
    ['redeem', 'Gifts Management', ['/gift']],
    ['recent_actors', 'E-Store Gifts Management', ['/livegift']],
    ['send', 'e-store request', ['/giftRequest']],
    ['add_to_home_screen', 'Banners', ['/banners']],
    ['money', 'Currency Exchange Rates', ['/currencyExchange']],
    ['monetization_on', 'Host Salary', ['/salary']],

  ]],
  ['swap_horiz', 'Violations', ['/violation']],
  ['account_circle', 'Banned User', ['/banuser']],
  ['developer_mode', 'App Management', [
    ['alarm_add', 'Events Management', ['/eventsManagement']],
    ['loyalty', 'Talent Tags', ['/talents']],
    ['description', 'Task Management', ['/task']],
    ['record_voice_over', 'Club reward management', ['/reward']],
    ['pause_presentation', 'Club commission management', ['/reward-slab-host']],
  ]],
  ['assessment', 'Show Events', ['/events']],
  ['alarm_on', 'Event Fixtures', ['/eventFixtures']],
  ['lock_open', 'Support', ['/support']],

  ['card_giftcard', 'VIP Purchase/Reward', ['/vip-rewards']],
  // ['card_giftcard', 'VIP-Reward', ['/vip-rewards']],
  ['phonelink_lock', 'Vip Subscription Offers', ['/vip-subscription-offers']],
  ['ballot', 'Host Application', ['/applicationList']],
  ['file_copy', 'Reports', [
    ['how_to_vote', 'Task Board', ['/taskBoard']],
    ['inbox', 'F2 Report', ['/f2-matches']],
    ['toys', 'Star Live Report', ['/star-live']],
    ['watch', 'Lucky Draw', ['/luckyDraw']],
    ['library_add', 'User Transaction', ['/user-transaction']],
    ['payment', 'Host Salary Report', ['/salaryReport']],
    ['record_voice_over', 'Club Reward', ['/clubRewardReport']],
    ['pause_presentation', 'Club Commission', ['/clubCommissionReport']],
  ]],
  ['movie_filter', 'Club', ['/club']],
  ['supervisor_account', 'Family', ['/family']],

  ['transfer_within_a_station', 'Encash Requests', ['/diamondFiat']]
  ,
  ['rate_review', 'Offers', ['/rates']],
  ['brightness_7', 'Settings', ['/dummy']],
];

const createSubSubMenuItem = ([icon, text, path]: [string, string, any]) => ({ icon, text, path });
const createSubMenuItem = ([icon, text, path]: [string, string, any]) => {
  const isSubmenuList = Array.isArray(path[0]);
  return {
    icon,
    text,
    path: isSubmenuList ? [] : path,
    subMenu: isSubmenuList ? path.map(createSubSubMenuItem) : [],
    isSubMenuOpen: false,
    id: uuid(),
  };
};;

export const menuItems = tempMenuData
  .map(([icon, text, path]) => {

    const isSubmenuList = Array.isArray(path[0]);
    return {
      icon,
      text,
      path: isSubmenuList ? [] : path,
      subMenu: isSubmenuList ? path.map(createSubMenuItem) : [],
      isSubMenuOpen: false,
      id: uuid(),
    };

  });