import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class StreamManagementService {

  constructor( private http: HttpClient,private settings: SettingsService) { }
  
  getStream(params: any): Promise<any> {
  
    const url = `${this.settings.API_BASE_URL}/users/stream`;
    return this.http.get(url+'/getAllStreams', { params }).toPromise();
  }

  getStreamById(params: any): Promise<any> {
  
    const url = `${this.settings.API_BASE_URL}/users/stream`;
    return this.http.get(url+'/'+params).toPromise();
  }

  getEncashRequest(body: any): Promise<any> {
  
    const url = `${this.settings.API_BASE_URL}/streams/getAllLiveData`;
    return this.http.post(url,body).toPromise();
  }

  getUserStreamByDate(body: any): Promise<any> {
  
    const url = `${this.settings.API_BASE_URL}/users/stream/getCalendarData`;
    return this.http.post(url, body).toPromise();
  }

  countByType(): Promise<any> {
  
    const url = `${this.settings.API_BASE_URL}/users/stream`;
    return this.http.get(url+'/countByType').toPromise();
  }
  exportCsv(params:any) {
    const url = `${this.settings.API_BASE_URL}/users/stream`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(url+'/getCsv', {params, headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf(params:any) {
    const url = `${this.settings.API_BASE_URL}/users/stream`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(url+'/getPdf', {params, headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }
}
