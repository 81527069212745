import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})

export class EventServiceService {
  id = '';
  hrStatus: string;
  constructor(private http: HttpClient, private settingsServe: SettingsService) { }
  private uri = `${this.settingsServe.API_BASE_URL}/events`;
  private uriForClubMembers = `${this.settingsServe.API_BASE_URL}/users`;
  private uriForApplication = `${this.settingsServe.API_BASE_URL}/application/users`;

  getEvents(params = {}): any {
    return this.http.get<any>(`${this.uri}`, { params }).toPromise();
  }

  getF2Events(params = {}): any {
    return this.http.get<any>(`${this.uri}/f2`, { params }).toPromise();
  }

  getF2EventsByUserId(params = {}, userId: string): any {
    return this.http.get<any>(`${this.uri}/f2/${userId}`, { params }).toPromise();
  }

  getStarLiveEvents(params = {}): any {
    return this.http.get<any>(`${this.uri}/startlive`, { params }).toPromise();
  }

  getStarLiveEventsByUserId(params = {}, userId: string): any {
    return this.http.get<any>(`${this.uri}/startlive/${userId}`, { params }).toPromise();
  }

  getClubEvents(params = {}): any {
    return this.http.get<any>(`${this.uri}/getClubEvent`, { params }).toPromise();
  }

  createEvent(data: any): Promise<any> {
    return this.http.post<any>(`${this.uri}`, data).toPromise();
  }

  updateEvent(id: any, data: any): Promise<any> {
    return this.http.put<any>(`${this.uri}/updateEvent/${id}`, data).toPromise();
  }

  getEventById(id: string): any {
    return this.http.get<any>(`${this.uri}/${id}`).toPromise();
  }
  getEventDetailsById(id: string): any {
    return this.http.get<any>(`${this.uri}/getEventById/${id}`).toPromise();
  }
  setId(id: string): any {
    this.id = id;
  }
  getId(): string {
    return this.id;
  }
  setHrStatus(status: string): void {
    this.hrStatus = status;
  }
  getHrStatus(): string {
    return this.hrStatus;
  }

  getAllEvents(params: any): Promise<any> {
    return this.http.get<any>(this.uri + '/getAllEvent', { params }).toPromise();
  }

  getEventByUser(params: any): Promise<any> {
    return this.http.post<any>(this.uri + '/getEventByUser', params).toPromise();
  }

  getClubEvent(params: any): Promise<any> {
    return this.http.get<any>(this.uri + '/getClubEvent', { params }).toPromise();
  }

  getEventsApplication(params: any): Promise<any> {
    return this.http.get<any>(this.uri + '/event-application', { params }).toPromise();
  }

  // getAllEvents(params: any): Promise<any> {
  //   return this.http.get<any>(this.uri+'/getAllEvent', { params }).toPromise();
  // }

  getEventCount(type: any): Promise<any> {
    return this.http.get<any>(`${this.uri}/getEventCount/${type}`).toPromise();
  }

  updateHrStatus(id: string, status: any): Promise<any> {
    return this.http.put(`${this.uri}/${id}`, status).toPromise();
  }
  getClubMembers(params = {}): any {
    return this.http.post<any>(`${this.uriForClubMembers}/getUserByClub`, params).toPromise();
  }
  updateSponsorshipStatus(id: string, params: any): Promise<any> {
    return this.http.put(`${this.uri}/${id}`, params).toPromise();
  }
  getMembersFromApplications(id: string, params: any): any {
    console.log(params);
    return this.http.get<any>(`${this.uriForApplication}/${id}`, { params }).toPromise();
  }

  /**
   * @param prevSlotEndTime Event Start Time as Date Object
   * @param endTime  Event End Time Date Object
   * @param duration  Slot Duration Number
   * @param slots Optional one: No need to pass
   * @returns slots
   */
  generateSlots(prevSlotEndTime: Date, endTime: Date, duration: number, slots: any[] = []): any[] {
    console.log(prevSlotEndTime)
    console.log(endTime)
    if (prevSlotEndTime >= endTime) {
      return slots;
    }

    if (prevSlotEndTime.getHours() !== 2) {
      const startTimeObj = new Date(prevSlotEndTime);
      const slot = {
        startTime: new Date(prevSlotEndTime),
        endTime: new Date(startTimeObj.setMinutes(startTimeObj.getMinutes() + duration)),
        users: [],
      };

      slots.push(slot);
      return this.generateSlots(slot.endTime, endTime, duration, slots);
    }

    if (prevSlotEndTime.getHours() === 2) {
      const tempPrevSlotEndTime = new Date(prevSlotEndTime);
      const temp = new Date(
        tempPrevSlotEndTime.setHours(tempPrevSlotEndTime.getHours() + 12, 0, 0, 0),
      );
      return this.generateSlots(temp, endTime, duration, slots);
    }

    return slots;
  }

  getEventApplicationStats(params: any): Promise<any> {
    return this.http.get(`${this.uri}/application-stats`, { params }).toPromise();
  }

  getEventApplications(eventId: string, params: any = {}): Promise<any> {
    return this.http.get(`${this.uri}/${eventId}/applications`, { params }).toPromise();
  }

  allotSlot(eventId: string, slotData: SlotAllotmentReqBody): Promise<any> {
    return this.http.post(`${this.uri}/${eventId}/applications/allot-slot`, slotData).toPromise();
  }

  getOpponentUserList(param: any): Promise<any> {
    return this.http.post(`${this.uri}/opponent-club-users-f2`, param).toPromise();
  }

  getEventMember(param: any): Promise<any> {
    return this.http.post(`${this.uri}/getEventMember`, param).toPromise();
  }

  updateApplicationStatus(eventId: string, applicationId: string, status: ApplicationStatusTypes): Promise<any> {
    return this.http.put(`${this.uri}/${eventId}/applications/${applicationId}`, { status }).toPromise();
  }

  getApplicationById(eventId: string, applicationId: string): Promise<any> {
    return this.http.get(`${this.uri}/${eventId}/applications/${applicationId}`).toPromise();
  }

  unAllotStarLiveSlot(eventId: string, data: { slotId: string }): Promise<any> {
    return this.http.put(`${this.uri}/${eventId}/applications/un-allocate-slot`, data).toPromise();
  }

  getSlotAllotmentReport(eventId: string, params: any): Promise<any> {
    return this.http.get(`${this.uri}/${eventId}/applications/slot-allocation-report`, { params }).toPromise();
  }

  getSlotAllotmentReportForUser(eventId: string, params: any): Promise<any> {
    return this.http.get(`${this.uri}/${eventId}/slot-allocation-report`, { params }).toPromise();
  }

  autoAllotSlots(eventId: string, body: any = {}): Promise<any> {
    return this.http.post(`${this.uri}/${eventId}/applications/auto-allot`, body).toPromise();
  }

  revertAutoAllotedSlots(eventId: string, sessionId: string): Promise<any> {
    return this.http.delete(`${this.uri}/${eventId}/applications/auto-allot`, { params: { sessionId } }).toPromise();
  }

  submitEventForSlotApproval(eventId: string, body?: { sessionId?: string }): Promise<any> {
    return this.http.post(`${this.uri}/${eventId}/applications/submit-slots`, body).toPromise();
  }
}

export interface EventQueryParams {
  [key: string]: any;
}

export interface ClubId {
  [key: string]: any;
}

export interface ApplicationParams {
  [key: string]: any;
}

export enum EVENT_TYPES {
  STAR_LIVE = 'STAR_LIVE',
  OFFICIAL_F2 = 'OFFICIAL_F2',
  OFFICIAL_F2_TOUR = 'OFFICIAL_F2_TOUR',
}

export enum ROLES {
  CLUB = 'CLUB',
  HR = 'HR',
  COUNTRY_HEAD = 'COUNTRY_HEAD',
  ZONAL_HEAD = 'ZONAL_HEAD',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export enum HRSTATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum CLUBSTATUS {
  DASH = '-',
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  EXPIRED = 'EXPIRED'
}

export interface Slot {
  id: string;
  applications?: string[];
  startTime: string | Date;
  endTime: string | Date;
  [key: string]: any;
}

export interface SlotAllotmentReqBody {
  id: string;
  applications: string[];
  startTime: string | Date;
  endTime: string | Date;
}

export enum ApplicationStatusTypes {
  REJECTED = 'REJECTED',
  SHORTLISTED = 'SHORTLISTED',
  REVIEWED = 'REVIEWED',
  PENDING = 'PENDING',
  ALLOTTED = 'ALLOTTED',
}
