<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Month</mat-label>
    <input matInput [(ngModel)]="month" type="number" min="1" max="12">
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Year</mat-label>
    <input matInput [(ngModel)]="year" type="number" min="2000">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button  class="app-primary-btn mr-5 custom-width-btn" (click)="onGenerate()">{{ buttonText }}</button>
</div>

