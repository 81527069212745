import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

export enum StaffRoles {
  COUNTRY_HEAD = 'COUNTRY_HEAD',
  ZONAL_HEAD = 'ZONAL_HEAD',
  HR = 'HR',
  CLUB = 'CLUB',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  private uri = `${this.settingsServe.API_BASE_URL}/staff`;

  constructor(
    private settingsServe: SettingsService,
    private http: HttpClient
  ) { }

  createStaff(data: any): Promise<any> {
    return this.http.post(this.uri, data).toPromise();
  }

  getStaffList(params: any): Promise<any> {
    return this.http.get<any>(this.uri, { params }).toPromise();
  }

  getClubRewardSalaryReport(params: any): Promise<any> {
    return this.http.post(`${this.uri}/getClubRewardSalary`, params ).toPromise();
  }

  generateClubRewardSalary(data: any): Promise<any> {
    return this.http.post(`${this.uri}/generateClubRewardSalary`, data).toPromise();
  }

  updateClubRewarsSalaryStatus(data: any): Promise<any> {
    return this.http.post(`${this.uri}/updateClubRewardSalaryStatus`, data).toPromise();
  }

  generateClubCommissionSalary(data: any): Promise<any> {
    return this.http.post(`${this.uri}/generateClubCommission`, data).toPromise();
  }

  getClubCommissionSalaryReport(params: any): Promise<any> {
    return this.http.post(`${this.uri}/getClubCommissionSalary`, params ).toPromise();
  }

  updateClubCommissionSalaryStatus(data: any): Promise<any> {
    return this.http.post(`${this.uri}/updateClubCommissionSalaryStatus`, data).toPromise();
  }

  getStaffCount(): Promise<any> {
    return this.http.get<any>(this.uri+'/getStaffCount').toPromise();
  }
  getStaffById(id: string): Promise<any> {
    return this.http.get<any>(`${this.uri}/${id}`).toPromise();
  }
  updateStaffById(id: string, data: any): Promise<any> {
    return this.http.put(`${this.uri}/${id}`, data).toPromise();
  }

  getStaffbyToken(): Promise<any> {
    return this.http.get(`${this.uri}/getStaffByToken`).toPromise();
  }

  getStaffDetailsByToken(): Promise<any> {
    return this.http.get(`${this.uri}/getStaffDetailsByToken`).toPromise();
  }

  getDisplayNameAndMoreId(): Promise<any> {
    return this.http.get(`${this.uri}/getDisplayNameAndMoreId`).toPromise();
  }

  uploadProfileImage(image: File): Promise<any> {
    const data = new FormData();
    data.append('image', image);
    return this.http.post(`${this.uri}/upload`, data).toPromise();
  }

  getClubDetails(params: any): Promise<any> {
    return this.http.get(`${this.uri}/role`, { params }).toPromise();

  }

  exportCsv(params:any) {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getCsv`, { params,headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf(params:any) {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getPdf`, {params, headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }


}
