import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BannerService } from 'src/app/services/banner/banner.service';
import { BeansDiamondService } from 'src/app/services/BeansDiamond/BeansDiamond-service.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { StaffService } from 'src/app/services/staff/staff.service';
import { EditService } from 'src/app/services/users/edit.service';
import { ViolationService } from 'src/app/services/violation/violation.service';
import { VipService } from 'src/app/services/vip/vip.service';
import { StreamManagementService } from 'src/app/streams/stream-management.service';

@Component({
  selector: 'app-export-report-btn',
  templateUrl: './export-report-btn.component.html',
  styleUrls: ['./export-report-btn.component.css'],
})
export class ExportReportBtnComponent implements OnInit {
  @Input() filterData: any;
  constructor(
    private userService: EditService,
    private staffService: StaffService,
    private giftService: GiftService,
    private bannerService: BannerService,
    private beansDiamondService: BeansDiamondService,
    private streamService: StreamManagementService,
    private violation: ViolationService,
    private vipService: VipService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  exportData(event: any) {
    const url = this.router.url;
    const params: any = {};
    switch (url) {
      case '/account':
        if (this.filterData?.countryName) {
          params.countryName = this.filterData?.countryName;
        }
        if (this.filterData?.club) {
          params.clubId = this.filterData?.club;
        }
        if (this.filterData?.vipLevel) {
          params.vipLevel = this.filterData?.vipLevel;
        }
        if (this.filterData?.role) {
          const role = this.filterData?.role;
          switch (role) {
            case 'USER':
              params.isOfficialHost = false;
              params.isCelebrity = false;
              break;
            case 'CELEBRITY HOST':
              params.isCelebrity = true;
              break;
            case 'OFFICIAL HOST':
              params.isOfficialHost = true;
              break;
          }
        }
        if (this.filterData?.registrationDateRange?.startDate) {
          params.startDate = this.filterData?.registrationDateRange?.startDate;
        }
        if (this.filterData?.registrationDateRange?.endDate) {
          params.endDate = this.filterData?.registrationDateRange?.endDate;
        }
        if (event === 'csv') {
          this.exportUserCsv(params);
        }
        if (event === 'pdf') {
          this.exportUserPdf(params);
        }
        if (event === 'email') {
          this.exportUserPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;
      case '/vip':
        if (event === 'csv') {
          this.exportVipUserCsv();
        }
        if (event === 'pdf') {
          this.exportVipUserPdf();
        }
        break;
      case '/staff':
        if (this.filterData?.country) {
          params.country = this.filterData?.country;
        }
        if (this.filterData?.role) {
          params.role = this.filterData?.role;
        }
        if (this.filterData?.zone) {
          params.zone = this.filterData?.zone;
        }

        if (event === 'csv') {
          this.exportStaffCsv(params);
        }
        if (event === 'pdf') {
          this.exportStaffPdf(params);
        }
        if (event === 'email') {
          this.exportStaffPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;
      case '/gift':
        if (this.filterData?.category) {
          if (this.filterData?.category === 'PURCHASE') {
            params.type = 'PURCHASE';
          } else {
            params.category = this.filterData?.category;
          }
        }
        if (this.filterData?.utilityType) {
          params.utilityType = this.filterData?.utilityType;
        }

        if (
          this.filterData?.isActive !== '' &&
          this.filterData?.isActive != null
        ) {
          params.isActive = this.filterData?.isActive;
        }

        if (event === 'csv') {
          this.exportGiftCsv(params);
        }
        if (event === 'pdf') {
          this.exportGiftPdf(params);
        }
        if (event === 'email') {
          this.exportGiftPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;
      case '/giftRequest':
      if (this.filterData?.request) {
        params.request = this.filterData?.request;
      }
        if (event === 'csv') {
          this.exportGiftRequestCsv(params);
        }
        if (event === 'pdf') {
          this.exportGiftRequestPdf(params);
        }
        if (event === 'email') {
          this.exportGiftRequestPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;
      case '/livegift':
        if (this.filterData?.utilityType) {
          params.utilityType = this.filterData?.utilityType;
        }
        if (
          this.filterData?.isActive !== '' &&
          this.filterData?.isActive != null
        ) {
          params.isActive = this.filterData?.isActive;
        }
        if (event === 'csv') {
          this.exportLiveGiftCsv(params);
        }
        if (event === 'pdf') {
          this.exportLiveGiftPdf(params);
        }
        if (event === 'email') {
          this.exportLiveGiftPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;

      case '/banners':

      if (this.filterData?.isActive !== '') {
        params.isActive = this.filterData?.isActive;
      }
        if (event === 'csv') {
          this.exportBannerCsv(params);
        }
        if (event === 'pdf') {
          this.exportBannerPdf(params);
        }
        if (event === 'email') {
          this.exportBannerPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;
      case '/beansDiamond':
        if (event === 'csv') {
          this.exportBeansToDiamondCsv();
        }
        if (event === 'pdf') {
          this.exporBeansToDiamondsPdf();
        }
        break;

      case '/diamondFiat':
      if (this.filterData?.registrationDateRange?.startDate) {
        params.startDate = this.filterData?.registrationDateRange?.startDate;
      }
      if (this.filterData?.registrationDateRange?.endDate) {
        params.endDate = this.filterData?.registrationDateRange?.endDate;
      }
      if (this.filterData?.status) {
        params.status = this.filterData?.status;
      }

      if (this.filterData?.amount) {
        params.amount = this.filterData?.amount;
      }


        if (event === 'csv') {
          this.exportDiamondToFiatCsv(params);
        }
        if (event === 'pdf') {
          this.exporDiamondToFiatPdf(params);
        }
        if (event === 'email') {
          this.exporDiamondToFiatPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;

      case '/diamondPurchase':
        if (event === 'csv') {
          this.exportDiamondPurchaseCsv();
        }
        if (event === 'pdf') {
          this.exporDiamondPurchasePdf();
        }
        break;

      case '/diamondBean':
        if (event === 'csv') {
          this.exportDiamondToBeanCsv();
        }
        if (event === 'pdf') {
          this.exporDiamondToBeanPdf();
        }
        break;

      case '/streams':
        if (this.filterData?.countryName) {
          params.countryName = this.filterData?.countryName;
        }
        if (this.filterData?.vipLevel && this.filterData?.vipLevel !== 'VIP') {
          params.vipLevel = this.filterData?.vipLevel;
        }

        if (this.filterData?.club) {
          params.clubId = this.filterData?.club;
        }

        if (this.filterData?.role) {
          const role = this.filterData?.role;
          switch (role) {
            case 'USER':
              params.isOfficialHost = false;
              params.isCelebrity = false;
              break;
            case 'CELEBRITY HOST':
              params.isCelebrity = true;
              break;
            case 'OFFICIAL HOST':
              params.isOfficialHost = true;
              break;
          }
        }

        switch (this.filterData?.liveEnded) {
          case 'LIVE':
            params.isEnded = 'Active';
            break;
          case 'ENDED':
            params.isEnded = 'ended';
            break;
        }

        if (this.filterData?.registrationDateRange?.startDate) {
          params.startDate = this.filterData?.registrationDateRange?.startDate;
        }
        if (this.filterData?.registrationDateRange?.endDate) {
          params.endDate = this.filterData?.registrationDateRange?.endDate;
        }

        if (event === 'csv') {
          this.exportStramCsv(params);
        }
        if (event === 'pdf') {
          this.exportStreamPdf(params);
        }
        if (event === 'email') {
          this.exportStreamPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;

      case '/violation':
      if (this.filterData?.registrationDateRange?.startDate) {
        params.startDate = this.filterData?.registrationDateRange?.startDate;
      }
      if (this.filterData?.registrationDateRange?.endDate) {
        params.endDate = this.filterData?.registrationDateRange?.endDate;
      }

      if (this.filterData?.country) {
        params.country = this.filterData?.country;
      }
      if (this.filterData?.vipLevel) {
        params.vipLevel = this.filterData?.vipLevel;
      }
      if (this.filterData?.role) {
        params.role = this.filterData?.role;
      }
      if (this.filterData?.broadcastStatus) {
        params.broadcastStatus = this.filterData?.broadcastStatus;
      }
      if (this.filterData?.club) {
        params.clubId = this.filterData?.club;
      }
    
      
        if (event === 'csv') {
          this.exportViolationCsv(params);
        }
        if (event === 'pdf') {
          this.exportViolationPdf(params);
        }
        if (event === 'email') {
          this.exportViolationPdf(params).then(() => {
            this.sendEmail();
          });
        }
        break;

      default:
        break;
    }
  }

  async sendEmail() {
    const recipient = 'example@gmail.com';
    const subject = encodeURIComponent('Report');
    const body = encodeURIComponent(
      'Hello,\n\nPlease find the attached report.\n\nBest Regards,'
    );

    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${subject}&body=${body}`;

    window.open(gmailLink, '_blank');
  }

  async exportStramCsv(params: any) {
    const response = await this.streamService.exportCsv(params);
    this.saveAsCSVFile(response.body, 'streams-');
  }
  async exportStreamPdf(params: any) {
    const response = await this.streamService.exportPdf(params);
    this.saveAsPdfFile(response.body, 'streams-');
  }

  async exportViolationCsv(params:any) {
    const response = await this.violation.exportCsv(params);
    this.saveAsCSVFile(response.body, 'violation-');
  }
  async exportViolationPdf(params:any) {
    const response = await this.violation.exportPdf(params);
    this.saveAsPdfFile(response.body, 'violation-');
  }
  async exportUserCsv(params: any) {
    const response = await this.userService.exportCsv(params);
    this.saveAsCSVFile(response.body, 'App-user-');
  }
  async exportUserPdf(params: any) {
    const response = await this.userService.exportPdf(params);
    this.saveAsPdfFile(response.body, 'App-user-');
  }

  async exportBannerCsv(params:any) {
    const response = await this.bannerService.exportCsv(params);
    this.saveAsCSVFile(response.body, 'banners-');
  }
  async exportBannerPdf(params:any) {
    const response = await this.bannerService.exportPdf(params);
    this.saveAsPdfFile(response.body, 'banners-');
  }

  async exportGiftCsv(params: any) {
    const response = await this.giftService.exportCsv(params);
    this.saveAsCSVFile(response.body, 'gift-');
  }
  async exportGiftPdf(params: any) {
    const response = await this.giftService.exportPdf(params);
    this.saveAsPdfFile(response.body, 'gift');
  }
  async exportLiveGiftCsv(params:any) {
    const response = await this.giftService.exportLiveGiftCsv(params);
    this.saveAsCSVFile(response.body, 'livegift-');
  }
  async exportLiveGiftPdf(params:any) {
    const response = await this.giftService.exportLiveGiftPdf(params);
    this.saveAsPdfFile(response.body, 'livegift-');
  }
  async exportGiftRequestCsv(params:any) {
    const response = await this.giftService.exportGiftRequestCsv(params);
    this.saveAsCSVFile(response.body, 'giftreuest-');
  }
  async exportGiftRequestPdf(params:any) {
    const response = await this.giftService.exportGiftRequestPdf(params);
    this.saveAsPdfFile(response.body, 'giftrequest');
  }
  async exportStaffCsv(params: any) {
    const response = await this.staffService.exportCsv(params);
    this.saveAsCSVFile(response.body, 'staff-');
  }
  async exportStaffPdf(params: any) {
    const response = await this.staffService.exportPdf(params);
    this.saveAsPdfFile(response.body, 'staff');
  }

  async exportDiamondToFiatCsv(params:any) {
    const response = await this.beansDiamondService.exportFiatCsv(params);
    this.saveAsCSVFile(response.body, 'diamonds-to-fiat');
  }
  async exporDiamondToFiatPdf(params:any) {
    const response = await this.beansDiamondService.exportFiatPdf(params);
    this.saveAsPdfFile(response.body, 'diamonds-to-fiat');
  }

  async exportDiamondPurchaseCsv() {
    const response = await this.beansDiamondService.exportDiamondPurchaseCsv();
    this.saveAsCSVFile(response.body, 'diamonds-purchase');
  }
  async exporDiamondPurchasePdf() {
    const response = await this.beansDiamondService.exportDiamondPurchasePdf();
    this.saveAsPdfFile(response.body, 'diamonds-purchase');
  }

  async exportDiamondToBeanCsv() {
    const response = await this.beansDiamondService.exportDiamondToBeanCsv();
    this.saveAsCSVFile(response.body, 'diamonds-to-bean');
  }
  async exporDiamondToBeanPdf() {
    const response = await this.beansDiamondService.exportDiamondToBeanPdf();
    this.saveAsPdfFile(response.body, 'diamonds-to-bean');
  }

  async exportBeansToDiamondCsv() {
    const response = await this.beansDiamondService.exportCsv();
    this.saveAsCSVFile(response.body, 'bean-to-diamonds');
  }
  async exporBeansToDiamondsPdf() {
    const response = await this.beansDiamondService.exportPdf();
    this.saveAsPdfFile(response.body, 'bean-to-diamonds');
  }

  saveAsPdfFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename =
      fileName +
      date.getFullYear() +
      date.getUTCMonth() +
      date.getUTCDate() +
      date.getUTCHours() +
      date.getUTCMinutes();
    const data: Blob = new Blob([buffer], {
      type: 'application/pdf;charset=utf-8',
    });
    FileSaver.saveAs(data, filename + '.pdf');
  }

  saveAsCSVFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename =
      fileName +
      date.getFullYear() +
      date.getUTCMonth() +
      date.getUTCDate() +
      date.getUTCHours() +
      date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    FileSaver.saveAs(data, filename + '.csv');
  }

  async exportVipUserCsv() {
    const response = await this.vipService.exportCsv();
    this.saveAsCSVFile(response.body, 'Vip-subscription-user-');
  }
  async exportVipUserPdf() {
    const response = await this.vipService.exportPdf();
    this.saveAsPdfFile(response.body, 'Vip-subscription-user-');
  }
}
