import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-salary-report-dialog',
  templateUrl: './salary-report-dialog.component.html',
  styleUrls: ['./salary-report-dialog.component.css']
})
export class SalaryReportDialogComponent {
  month: number | null = null;
  year: number | null = null;
  title: string;
  buttonText: string;

  constructor(
    public dialogRef: MatDialogRef<SalaryReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: 'generateReport' | 'exportBeans' }
  ) {
    if (data.action === 'generateReport') {
      this.title = 'Generate Salary Report';
      this.buttonText = 'Generate Salary';
    } else if (data.action === 'exportBeans') {
      this.title = 'Export Salary Report CSV file';
      this.buttonText = 'Download CSV file';
    }
  }

  onGenerate(): void {
    this.dialogRef.close({ month: this.month, year: this.year });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

