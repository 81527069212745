import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/common/commonService';
import { GiftCategory, GiftService } from 'src/app/services/gift/gift.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  editCategory: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { category: GiftCategory },
    private dialogRef: MatDialogRef<EditCategoryComponent>,
    private giftServe: GiftService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private snackbar: MatSnackBar) {
    this.editCategory = this.fb.group({
      name: [''],
      confirm: ['']
    });
  }

  ngOnInit(): void {
    this.editCategory.get('confirm')?.valueChanges.subscribe((data) => {
      const { name } = this.editCategory.value;
      if (name !== data) {
        this.editCategory.get('confirm')?.setErrors({ noMatch: true });
      } else {
        this.editCategory.get('confirm')?.setErrors(null);
      }
    });
    this.editCategory.get('name')?.valueChanges.subscribe((data) => {
      const { confirm } = this.editCategory.value;
      if (confirm !== data) {
        this.editCategory.get('confirm')?.setErrors({ noMatch: true });
      } else {
        this.editCategory.get('confirm')?.setErrors(null);
      }
    });
  }

  async updateGiftServe(): Promise<void> {
    try {
      this.commonService.showLoading();
      const { name } = this.editCategory.value;
      const result = await this.giftServe.updateCategory(this.data?.category?.id, { name });
      this.dialogRef.close(true);
      this.commonService.hideLoading();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'close', { duration: 2000 });
    }
  }

}
