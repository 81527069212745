import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { CommonService } from '../common/commonService';
import { EventServiceService } from '../services/events/event-service.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-f2-matches',
  templateUrl: './f2-matches.component.html',
  styleUrls: ['./f2-matches.component.css']
})
export class F2MatchesComponent implements OnInit {

  total = 0;
  tabIndex=0;
  pageIndex = 0;
  pageSize = 5;
  filterForm!: FormGroup;
  filters: any;
  sort = {
    active: '',
    direction: ''
  };
  applicationData: any = [];

  constructor(
    private fb: FormBuilder,
    private eventService: EventServiceService,
    private commonService: CommonService,
    private snackbar: MatSnackBar
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getAllApplication();
  }

  sortData(sort: Sort) {

    this.sort = sort;
    this.getAllApplication();
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
    this.getAllApplication();
  }


  async getAllApplication(): Promise<any> {
    try {
      this.commonService.showLoading();
      const params: any = {
        offset: this.offset,
        f2Type:this.tabIndex == 0 ?"NORMAL_F2":"OFFICIAL_F2",
        limit: this.pageSize
      };
      if (this.filters?.registrationDateRange) {
        if (this.filters.registrationDateRange.startDate) {
          params.startDate = this.filters.registrationDateRange.startDate;
          params.endDate = this.filters.registrationDateRange.endDate;
        }
      }

      if (this.sort?.active) {
        params.active = this.sort?.active;
        params.direction = this.sort?.direction;
      }
      if (this.filters?.status) {
        params.status = this.filters.status;
      }

      const data = await this.eventService.getF2Events(params);
      this.applicationData = data.response.data;
      this.total = data.response.total;

      this.commonService.hideLoading();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'close', { duration: 2000 });
    }
  }

  initForm(): void {
    this.filterForm = this.fb.group({
      registrationDateRange: this.fb.group({
        startDate: [''],
        endDate: ['']
      })
    });
  }

  apply(): void {
    this.pageIndex = 0;
    this.filters = this.filterForm.value;
    this.getAllApplication();
  }



  handlePageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllApplication();
  }

  clearFilter() {
    this.filterForm.reset();
    this.filters = {}; 
    this.pageIndex = 0; 
    this.getAllApplication();

  }

  get offset(): number {
    return this.pageIndex * this.pageSize;
  }

}
