<div class="container-fluid pt-4">
    <div class="row justify-content-center">
        <div class="col-12 col-md-11">
            
            <section class="row my-3">
                <div class="col-12 d-flex justify-content-between">
                    <h2 class="ml-3 font-weight-bold">F2 MATCHES</h2>
                </div>
            </section>

            <div class="container-fluid p-4 card-style-table">
              

                <!-- Filter controls starts -->
                <section class="row">
                    <div class="col-12">
                        <form [formGroup]="filterForm" (ngSubmit)="apply()">
                            <div class="row">
                                <!-- DATE range -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>DATE RANGE</mat-label>
                                        <mat-date-range-input [rangePicker]="picker"
                                            formGroupName="registrationDateRange">
                                            <input matStartDate placeholder="Start date" formControlName="startDate">
                                            <input matEndDate placeholder="End date" formControlName="endDate">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md">
                                    <div style="height: 59px;"
                                        class="d-md-flex justify-content-md-end align-items-center">
                                        <button type="submit" class="app-primary-btn mr-2" mat-flat-button>Apply</button>
                                        <button type="button" mat-flat-button class="app-primary-btn"
                                            (click)="clearFilter()">Clear
                                            Filter</button>
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>

                <div class="container-fluid p-4 card-style-table">
                    <section class="mt-3 row justify-content-center">
                        <div class="col-12 col-lg-12">
                            <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)" dynamicHeight>
                                <mat-tab label="USER F2">
                                    <section class="table-responsive">
                                        <table matSort (matSortChange)="sortData($event)" class="table app-rounded-gray-hd-table w-100 text-center table-borderless">
                                            <thead class="thead-bg">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Name</th>
                                                    <th  scope="col">MOR ID</th>
                                                    <th  scope="col">Opponent Name</th>
                                                    <th  scope="col">Opponent Mor Id</th>
                                                    <th   mat-sort-header="createdAt">Date</th>
                                                    <th   mat-sort-header="timeInSeconds">Duration</th>
                                                    <th scope="col">Type</th>
                                                    <th   mat-sort-header="fameOnePoints">Bean Received</th>
                                                    <th   mat-sort-header="fameTwoPoints">Opponent Bean Received</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let each of applicationData; let i=index;">
                                                    <td>{{pageIndex*pageSize+i+1}}</td>
                                                    <td>
                                                        {{ each?.fameOneName }}
                                                        <span *ngIf="each?.fameOneStatus === 'win'" class="winner-dot"></span>
                                                    </td>
                                                    <td>{{each?.fameOneMorId}}</td>
                                                    <td>
                                                        {{ each?.fameTwoName }}
                                                        <span *ngIf="each?.fameTwoStatus === 'win'" class="winner-dot"></span>
                                                    </td>
                                                    <td>{{each?.fameTwoMorId}}</td>
                                                    <td>{{each?.dateAndTime | date:'MMM d, y, h:mm:ss a'}}</td>
                                                    <td>{{each?.f2Duration}}</td>
                                                    <td>NA</td>
                                                   <td>{{each?.fameOneBeansReceived == 0 ? 0 : each?.fameOneBeansReceived}}</td>
                                                   <td>{{each?.fameTwoBeansReceived == 0 ? 0 : each?.fameTwoBeansReceived}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <mat-paginator  [pageIndex]="pageIndex"[length]="total" [pageSizeOptions]="[5, 10, 25, 100]"
                                            [pageSize]="pageSize" (page)="handlePageChange($event)">
                                        </mat-paginator>
                                    </section>
                                </mat-tab>
                                <mat-tab label="OFFICIAL F2">
                                    <p>OFFICIAL F2</p>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>