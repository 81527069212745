import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GiftCategory } from 'src/app/services/gift/gift.service';

@Component({
  selector: 'app-gif',
  templateUrl: './gif.component.html',
  styleUrls: ['./gif.component.css']
})
export class GifComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { image: string },
    private dialogRef: MatDialogRef<GifComponent>,
  ) { }

  ngOnInit(): void {
  }

}
