<div class="d-flex flex-row justify-content-center my-flex-container" mat-dialog-title>
    <h1>Confirm Approval</h1>
</div>

<div>
    <h3>Are you sure you want to approve this Event?</h3>
    <div class="d-flex flex-row justify-content-center my-flex-container" mat-dialog-actions>
        <button class=" btn btn-primary form-button mr-2" type="button" mat-dialog-close (click)="back()">Back</button>

        <button class="btn btn-success form-button" type="button" (click)=setStatus() mat-dialog-close>Approve</button>
    </div>


</div>