import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../common/commonService';
import { EditService } from 'src/app/services/users/edit.service';
import { PageEvent } from '@angular/material/paginator';
import { zip } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { SalaryReportDialogComponent } from './salary-report-dialogue/salary-report-dialog/salary-report-dialog.component';


@Component({
  selector: 'app-host-salary-report',
  templateUrl: './host-salary-report.component.html',
  styleUrls: ['./host-salary-report.component.css']
})
export class HostSalaryReportComponent implements OnInit {

  total = 0;
  pageIndex = 0;
  pageSize = 5;
  filterForm!: FormGroup;
  filters: any;
  sort = {
    active: '',
    direction: ''
  };
  data: any = [];
  selectedHost: any = null;

  @ViewChild('statusConfirmation') statusConfirmation: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private editService: EditService,
    private commonService: CommonService,
    private snackbar: MatSnackBar
  ) {
    this.initForm();
  }


  openGenerateSalaryReportDialog(action: 'generateReport' | 'exportBeans'): void {
    const dialogRef = this.dialog.open(SalaryReportDialogComponent,{
      data: { action }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const { month, year } = result;
        if (action === 'generateReport') {
          this.generateSalaryReport(month, year);
        } else if (action === 'exportBeans') {
          this.exportApprovedBeans(month, year);
        }
      }
    });
  }


  async generateSalaryReport(month: number, year: number): Promise<void> {
    try {
      this.commonService.showLoading();
      const response = await this.editService.generateSalaryReport({ month, year });
      
      this.snackbar.open('Salary report generated successfully', 'Close', { duration: 2000 });
      this.commonService.hideLoading();
      this.getSalaryReport();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'Close', { duration: 2000 });
    }
  }



  async getSalaryReport(): Promise<void> {
    try {
      this.commonService.showLoading();
      var params: any = {
        offset: this.offset,
        limit: this.pageSize,
        ...this.filters
      };

      if (this.filters?.month) {
        params.month = this.filters?.month;
      }
      if (this.filters?.year) {
        params.year = this.filters?.year;
      }
      // console.log("params ", params);
      const response = await this.editService.getHostSalaryReport(params);
      this.data = response.response.data;
      this.total = response.response.totalRecord;
      
      

      this.commonService.hideLoading();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'close', { duration: 2000 });
    }
  }

  initForm() {
    this.filterForm = this.fb.group({
      month: [''],
      year: ['']
    });
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.filters = {};
    this.getSalaryReport();
  }

  applyFilter() {
    this.pageIndex = 0;
    this.filters = this.filterForm.value;
    this.getSalaryReport();
  }

  handlePageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getSalaryReport();
  }

  saveAsCSVFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    FileSaver.saveAs(data, filename + ".csv");
  }

  async exportApprovedBeans(month: number, year: number): Promise<void> {
    try {
      this.commonService.showLoading();
      const response = await this.editService.exportApprovedBeans(month, year); 
      this.saveAsCSVFile(response.body, 'Approved-Beans');
      this.snackbar.open('Approved Beans exported successfully', 'Close', { duration: 2000 });
      this.commonService.hideLoading();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'Close', { duration: 2000 });
    }
  }
  

  get offset(): number {
    return this.pageIndex * this.pageSize;
  }

  ngOnInit(): void {
    this.getSalaryReport();
  }


  openStatusConfirmation(host: any) {
    this.selectedHost = host;
    this.dialog.open(this.statusConfirmation);
  }

  async updateSalaryStatus() {
    if (this.selectedHost) {
      try {
        this.commonService.showLoading();
        const response = await this.editService.updateSalaryStatus({
          morId: this.selectedHost.morId,
          salaryId: this.selectedHost.id
        });
        this.snackbar.open('Salary status updated successfully', 'Close', { duration: 2000 });
        this.dialog.closeAll();
        this.getSalaryReport();
      } catch (error: any) {
        this.commonService.hideLoading();
        this.snackbar.open(error?.error?.message ?? 'Failed server request', 'Close', { duration: 2000 });
      }
    }
  }

  cancelStatusUpdate() {
    this.dialog.closeAll();
  }

}
