<div class="container-fluid pt-4">
  <div class="row justify-content-center">
    <div class="col-12 col-md-11">
      <section class="row my-3">
        <div class="col-12 d-flex justify-content-between">
          <h2 class="ml-3 font-weight-bold">Host Salary Report</h2>
          <button
          class="export-report-btn px-2"
          (click)="openGenerateSalaryReportDialog('exportBeans')"
          type="button"
        >
          <img
            class=""
            loading="lazy"
            decoding="async"
            alt=""
            src="/assets/accountIcons/ic-file-download.svg"
          />
          Export Salary report
        </button>
        </div>
      </section>

      <div class="container-fluid p-4 card-style-table">
        <section class="row">
          <div class="col-12">
            <form
              class="form"
              [formGroup]="filterForm"
              (ngSubmit)="applyFilter()"
            >
              <div class="row">
                <div>
                  <div>Select salary month and year</div>
                  <div style="display: flex">
                    <div class="col-12 col-md-6">
                      <mat-form-field
                        appearance="outline"
                        class="w-100"
                        class="custom-form-field"
                      >
                        <mat-label>Month</mat-label>
                        <input
                          matInput
                          placeholder="Month"
                          formControlName="month"
                          type="number"
                          min="1"
                          max="12"
                        />
                      </mat-form-field>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3"
                      class="custom-form-field"
                    >
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Year</mat-label>
                        <input
                          matInput
                          placeholder="Year"
                          formControlName="year"
                          type="number"
                          min="2000"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div
                    style="height: 59px"
                    class="d-md-flex justify-content-md-end align-items-center"
                  >
                    <button
                    class="app-primary-btn mr-2 custom-width-btn"
                    (click)="openGenerateSalaryReportDialog('generateReport')"
                    type="button"
                  >
                    Generate Salary Report
                  </button>

                    <button
                      type="button"
                      mat-flat-button
                      class="app-primary-btn mr-2"
                      (click)="resetFilters()"
                    >
                      Clear Filter
                    </button>
                    <button
                      type="submit"
                      class="app-primary-btn mr-5"
                      mat-flat-button
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        <section class="table-responsive">
          <table class="table w-100 text-center table-borderless">
            <thead class="thead-bg">
              <tr>
                <th>S.No</th>
                <th>MOR Id</th>
                <th>Display Name</th>
                <th>Registered On</th>
                <th>Host Type</th>
                <th>Salary month</th>
                <th>Monthly beans received</th>
                <th>Incentive slab (%)</th>
                <th>Total Salary Amount</th>
                
                <th>Host Salary Amount</th>
                <th>Bonus</th>

                <th>Salary Payable</th>

                <th>Club commisssion Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let each of data; let i = index">
                <td>{{ offset + i + 1 }}</td>
                <td>{{ each?.morId }}</td>
                <td>{{ each?.displayName }}</td>
                <td>
                  {{ each?.userRegisterDate | date : "MMM d, y, h:mm:ss a" }}
                </td>
                <td>
                  <span *ngIf="each?.isOfficialHost">Official Host</span>
                  <span *ngIf="!each?.isOfficialHost && each?.isCelebrity"
                    >Celebrity</span
                  >
                </td>
                <td>{{ each?.salaryMonth }}-{{ each?.salaryYear }}</td>
                <td>{{ each?.monthlyBeansCount }}</td>
                <td>{{ each?.slabPR }}</td>
                <td>{{ each?.salaryAmount }}</td>
                
                <td>{{ each?.officialHostSalaryAmount }}</td>
                <td>{{ each?.bonus }}</td>

                <td>{{ each?.payableAmountToHost }}</td>
                <!-- <td>{{ each?.officialHostSalaryAmount }}</td> -->
                <td>{{ each?.clubCommissionAmount }}</td>
                <!-- <td>
                                    <span [ngClass]="{'bg-green': each.isPaid, 'bg-red': !each.isPaid}">
                                        {{ each.isPaid ? 'Paid' : 'Unpaid' }}
                                    </span>
                                    <button *ngIf="!each.isPaid" class="btn btn-primary" (click)="openStatusConfirmation(each)">Status</button>
                                </td> -->
                <td>
                  <span
                    *ngIf="each.isPaid"
                    [ngClass]="{ 'bg-green': each.isPaid }"
                  >
                    Paid
                  </span>
                  <span
                    *ngIf="!each.isPaid"
                    [ngClass]="{ 'bg-red': !each.isPaid }"
                    style="cursor: pointer;"
                    (click)="openStatusConfirmation(each)"
                  >
                    Unpaid
                  </span>
                </td>

              </tr>
            </tbody>
          </table>
          <mat-paginator
          [pageIndex]="pageIndex"
            [length]="total"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [pageSize]="pageSize"
            (page)="handlePageChange($event)"
          >
          </mat-paginator>
        </section>
        <ng-template #statusConfirmation>
          <h2 mat-dialog-title>Confirmation about paid salary</h2>
          <mat-dialog-content>
            <p>Are you paid salary to {{ selectedHost?.displayName }}?</p>
          </mat-dialog-content>
          <mat-dialog-actions>
            <button class="app-primary-btn"
            mat-button (click)="updateSalaryStatus()">Paid</button>
            <button mat-button (click)="cancelStatusUpdate()">Cancel</button>
          </mat-dialog-actions>
        </ng-template>
      </div>
    </div>
  </div>
</div>
