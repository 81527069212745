<button class="export-report-btn px-2" type="button" mat-flat-button [matMenuTriggerFor]="menu">
    <img class="" loading="lazy" decoding="async" alt="" src="/assets/accountIcons/ic-file-download.svg">
    Export
</button>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="exportData('csv')">
        <mat-icon ><i class="fas fa-file-csv" ></i></mat-icon>CSV
    </button>
    <button mat-menu-item (click)="exportData('pdf')">
        <mat-icon>picture_as_pdf</mat-icon>PDF
    </button>
    <button mat-menu-item (click)="exportData('email')">
        <mat-icon>email</mat-icon>EMAIL
    </button>
</mat-menu>