import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  isLoggedIn = false;
  @Input() snav: any;
  @Input() UserDetail: any;
  title = '';
  isnotify = true;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.isLoggedIn();
  }

  toggleSideNav() {
    this.snav?.toggle();
  }


  openChangePasswordModal() {
    // this.dialog.open(UpdatePasswordComponent);
  }


  showLoginIcon() {
    this.isLoggedIn = this.auth.isLoggedIn();
  }

  logout() {
    this.auth.logout();
    console.log('Clicked On Logout');
  }
  clear() {
    this.isnotify = false;
  }
  notify() {

    document.getElementById('idForNotification')?.setAttribute('class', 'notification-area-style');
  }

}
