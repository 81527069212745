import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterOutlet } from '@angular/router';
import { SeoService } from 'src/app/services/seo/seo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/token/token.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { fader } from 'src/app/animations/router-animation/router-animation';
import { menuItems } from './side-menu';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StaffService } from 'src/app/services/staff/staff.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.css'],
  animations: [
    fader,
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0.8,
      })),
      transition('open => closed', [
        animate('2s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),

  ]
})
export class AppShellComponent implements OnInit, OnDestroy, AfterViewInit {

  role = '';
  menuItems = menuItems;
  UserDetail: any = {};
  mobileQuery = this.media.matchMedia('(max-width: 600px)');
  private subscriptions: Subscription[] = [];
  title = '';
  isLoggedIn = false;

  constructor(
    private tokenServe: TokenService,
    private media: MediaMatcher,
    private auth: AuthService,
    // private dialog: MatDialog,
    private seo: SeoService,
    private router: Router,
    private staffService: StaffService,
    private token: TokenService
  ) {

    this.role = token.getTokenData();
    this.getUserDetails();

  }

  ngAfterViewInit(): void {
    this.setMenu();
  }


  ngOnInit(): void {
    if (this.router.url === '/') {
      this.router.navigate(['dashboard']);
    }
    this.showLoginIcon();
    this.setMenu();
  }

  async setMenu() {

    for (let index = 0; index < this.menuItems.length; index++) {
      const item = this.menuItems[index];

      if (item.text === 'Dashboard' && this.role === 'CLUB') {
        item.text = 'Leaderboard';
      }

      
      switch (true) {

        case item.text === 'Asset Management' && !['SUPER_ADMIN', 'HR'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;

        case ['Asset Management',].includes(item.text) && ['COUNTRY_HEAD'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;

        case [ 'Violations', 'App Management', 'Asset Management', 'Support', ,'Host Application',
      'Event Fixtures','Reports','Encash Requests',"Club"].includes(item.text) && ['CLUB'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;

        case (item.text === 'Club' || item.text === 'Familly' ) && !['CLUB'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;

          case  item.text === 'Family' && !['CLUB'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;
  
        case item.text === 'Settings' && !['SUPER_ADMIN', 'HR', 'ADMIN'].includes(this.role):
          this.menuItems.splice(index, 1);
          break;
      }

      if (item.text === 'Account Management') {
        if (this.role === 'ADMIN' || this.role === 'ZONAL_HEAD') {
          item.subMenu = item.subMenu.filter(
            (subItem: any) => !['Staff Management', 'Zone Management'].includes(subItem.text)
          );
        } else if (this.role === 'COUNTRY_HEAD') {
          item.subMenu = item.subMenu.filter(
            (subItem: any) => subItem.text !== 'Staff Management'
          );
        }else if (this.role === 'CLUB') {

          item.subMenu = item.subMenu.filter(
            (subItem: any) => !['Staff Management', 'Zone Management','App Users',].includes(subItem.text)
          );


          const clubSubMenuExists = item.subMenu.some(
            (subItem: any) => subItem.text === 'Club User'
          );
        
          if (!clubSubMenuExists) {
            const clubSubMenu = {
              icon: 'movie_filter',
              text: 'Club User',
              path: ['/club'],
              subMenu: [],
              isSubMenuOpen: false,
              id: uuid(),
            };
            const streamIndex = item.subMenu.findIndex(
              (subItem: any) => subItem.text === 'Stream Management'
            );
            if (streamIndex !== -1) {
              item.subMenu.splice(streamIndex, 0, clubSubMenu); // Add at the correct position
            } else {
              // Fallback: Add to the end if 'Stream Management' was missing
              item.subMenu.push(clubSubMenu);
            }
          }

        }
      }
      
      if (['App Management', 'Event Fixtures','Vip Subscription Offers','Encash Requests'].includes(item.text) && this.role === 'ADMIN') {
        this.menuItems.splice(index, 1);
      }


      if ([ 'Event Fixtures','Vip Subscription Offers','Encash Requests'].includes(item.text) && this.role === 'ZONAL_HEAD') {
        this.menuItems.splice(index, 1);
      }


      if (['Vip Subscription Offers'].includes(item.text) && this.role === 'COUNTRY_HEAD') {
        this.menuItems.splice(index, 1);
      }


    

      if (['App Management'].includes(item.text) && this.role === 'ZONAL_HEAD') {
        item.subMenu = item.subMenu.filter(
          (subItem: any) => !['Club commission management','Club reward management'].includes(subItem.text)
        );
        
      }

      if (item.text === 'Reports' && (this.role === 'ADMIN' || this.role === 'COUNTRY_HEAD' || this.role === 'ZONAL_HEAD')) {
        item.subMenu = item.subMenu.filter(
          (subItem: any) => !['User Transaction', 'Host Salary Report','Club Reward','Club Commission'].includes(subItem.text)
        );
      }

    }

  }

  async getUserDetails(): Promise<void> {
    try {
      let result = await this.staffService.getStaffbyToken();
      this.UserDetail = result.response;
      this.tokenServe.saveUserId(result.response.id);
      this.role = this.UserDetail.role;
      this.setMenu();
    } catch (error) {
      console.log(error);
    }
  }

  showLoginIcon(): void {
    this.isLoggedIn = this.auth.isLoggedIn();
  }


  private listenForTitleChanges(): void {
    const subscription = this.seo.subsject.subscribe((title) => {
      this.title = title;
    });

    this.subscriptions.push(subscription);
  }

  prepareAnimations(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openSubMenu(navItem: any, menuItems: any): void {
    navItem.isSubMenuOpen = !navItem.isSubMenuOpen;
    menuItems.forEach(function (x: any) {
      if (x.id !== navItem.id) {
        x.isSubMenuOpen = false;
      }
    });

  }

}
